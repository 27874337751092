h3 {
  text-align: left;
  margin-top: 30px;
}

.accordion {
  background-color: rgba(255, 255, 255, 0.202);
  margin-top: 15px;
  font-size: 30px;
  color: #ffffff;
  border: 1px solid white;
  cursor: pointer;
  padding: 5px;
  width: fit-content;
  border: none;
  text-align: left;
  outline: none;
  margin-left: 50px;

  text-transform: uppercase;
}

.fa-hand-point-right {
  color: #171539;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
}
.leftHeading {
  text-align: left;
}

.active,
.accordion:hover {
  background-color: white;
  color: #f8049c;
}

.active,
.fa-hand-point-right {
  color: #f8049c;
}
.panel {
  margin: 10px 50px;
}
.JenFile {
  margin-left: 50px;
}
