body {
  font-family: "Poppins", sans-serif;
  background-color: #171539;
  color: white;
}
h2 {
  margin-bottom: 20px;
}
h3 {
  font-family: "Pacifico", cursive;
  font-size: 50px;
  text-align: center;
  color: #f8049c;
}
h3.pair {
  text-align: center;
}
h4 {
  font-family: "Pacifico", cursive;
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
  color: #f8049c;
}
h5 {
  line-height: 40px;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 10px;
}
h6 {
  font-size: small;
  text-align: center;
}
a {
  text-decoration: none;
  color: white;
}
.collapsible {
  background: none;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active,
.collapsible:hover {
  color: #f8049c;
}

.MDco {
  color: #f8049c;
  background-color: rgba(255, 255, 255, 0.2);
}

.heading {
  font-family: "Pacifico", cursive;
  font-size: 100px;
  color: white;
  text-align: center;
}
.sub {
  font-size: 30px;
  color: #0cc0df;
  font-family: "Poppins";
  text-transform: uppercase;
  margin-bottom: 50px;
  letter-spacing: 5px;
  text-align: center;
}
.subheading {
  font-size: 20px;
  color: #0cc0df;
  font-family: "Poppins";
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
  margin: 20px 10px;
}
.intro-parts {
  margin-top: 100px;
}
.Jen-Headshot {
  margin-bottom: 100px;
  max-height: 500px;
}

/**--------buttons-------**/

.btn-outline-primary {
  color: #0cc0df;
  border: solid 2px #0cc0df;
}

.btn-outline-primary:hover {
  background-color: #f8049c;
  color: white;
  border: solid 2px white;
}

/**--------font awesome-------**/

.fa-react,
.fa-bootstrap,
.fa-apple,
.svgVS {
  color: white;
  width: 60px;
  height: 60px;
}
.fa-arrow-down-long {
  height: 50px;
  color: #0cc0df;
  margin-top: 10px;
}
.fa-arrow-down-long:hover {
  animation: spin 1s linear;
}

.fa-react:hover {
  color: #0cc0df;
  animation: spin 1s linear;
}
.fa-bootstrap:hover {
  color: #7a43b6;
  animation: spin 1s linear;
}
.fa-apple:hover {
  color: #a0a0a0;
  animation: spin 1s linear;
}
.svgVS:hover {
  animation: spin 1s linear;
}

.col-3 {
  text-align: center;
  max-width: 300px;
  max-height: 300px;
  margin: 0 auto;
  padding: none;
}
.GlowUp {
  background-color: white;
  padding: 48px 15px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/**--------footer-------**/

.footer {
  text-align: center;
}
